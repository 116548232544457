import {
  addSingleAlert,
  createAPIError
} from "redux/Notification/NotificationModule";
import { processFailToFetch } from "utilities/handleFetchErrors";

import actions from "./actions";

export const fetchWeeklyInteractions = (runId, productId) => async (
  dispatch,
  getState
) => {
  try {
    const payload = {
      runId,
      productId
    };

    const response = await fetch(
      `${getState().app.baseURL}JsonInteractionSvc.svc/interactions`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();

    if (!response.ok) {
      // status looks bad
      const alert = {
        id: 20,
        type: "danger",
        event: "Interactions Error",
        headline: "Error accessing the interactions api",
        message: `runId: ${runId}  fetch interactions : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `runId: ${runId}  interactions : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 5000, true));
      dispatch(actions.actionError(alert));
      dispatch(
        createAPIError({
          type: "CREATE_API_ENTITY_ERROR",
          errorType: "warning",
          runId,
          message: "Entity error while creating api"
        })
      );
      return false;
    } else if (json.getUnCommittedInteractionsResult.status === "error") {
      dispatch(actions.actionError(json.getUnCommittedInteractionsResult));
      return false;
    }
    dispatch(
      actions.interactionsReceived(
        JSON.parse(json.getUnCommittedInteractionsResult.payload)
      )
    );
    return true;
  } catch (e) {
    processFailToFetch(
      e,
      getState(),
      dispatch,
      addSingleAlert,
      "fetchWeeklyInteractions"
    );
    return false;
  }
};

/* inserts */

export const insertConference = conferenceItem => async (
  dispatch,
  getState
) => {
  const runId = conferenceItem.RunId;
  const tzoffset = conferenceItem.StartDate.getTimezoneOffset() * 60000; // offset in milliseconds
  const localISOTime = new Date(conferenceItem.StartDate - tzoffset)
  .toISOString()
  .slice(0, -1);

  try {
    const payload = {
      runId: conferenceItem.RunId,
      dayOfWeek: conferenceItem.DayOfWeek,
      topic: conferenceItem.TopicNumber,
      duration: conferenceItem.DurationId,
      feedback: conferenceItem.FeedbackId,
      workerId: conferenceItem.WorkerId,
      startTime: localISOTime
    };

    const response = await fetch(
      `${getState().app.baseURL}JsonInteractionSvc.svc/insertConference`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();
    if (!response.ok) {
      // status looks bad
      const alert = {
        id: 20,
        type: "danger",
        event: "Interactions Error",
        headline: "Error accessing the insertConference api",
        message: `runId: ${runId}  insertConference : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `runId: ${runId}  insertConference : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 5000, true));
      dispatch(actions.actionError(alert));
      dispatch(
        createAPIError({
          type: "CREATE_API_ENTITY_ERROR",
          errorType: "warning",
          runId,
          message: "Entity error while creating api"
        })
      );
    } else if (json.insertUncommittedConferenceResult.status === "error") {
      const alert = {
        id: 20,
        type: "danger",
        event: "Interactions Error",
        headline: "Error in the insertConference response",
        message: `runId: ${runId}  insertConference : response status : ${json.insertUncommittedConferenceResult.statusMessage}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `runId: ${runId}  insertConference : response status : ${json.insertUncommittedConferenceResult.statusMessage}`
      };
      dispatch(addSingleAlert(alert, notificationData, 5000, true));
      dispatch(actions.insertError(json.insertUncommittedConferenceResult));
    } else {
      actions.interactionsReceived(
        JSON.parse(json.insertUncommittedConferenceResult.payload)
      );
      dispatch(
        actions.interactionsReceived(
          JSON.parse(json.insertUncommittedConferenceResult.payload)
        )
        // actions.conferenceInserted(
        //   JSON.parse(json.insertUncommittedConferenceResult.payload)
        // )
      );
    }
  } catch (e) {
    processFailToFetch(
      e,
      getState(),
      dispatch,
      addSingleAlert,
      "insertConference"
    );
  }
};

export const insertMeeting = meetingItem => async (dispatch, getState) => {
  const tzoffset = meetingItem.StartDate.getTimezoneOffset() * 60000; // offset in milliseconds
  const localISOTime = new Date(meetingItem.StartDate - tzoffset)
    .toISOString()
    .slice(0, -1);

  const runId = meetingItem.RunId;
  try {
    const payload = {
      runId: meetingItem.RunId,
      dayOfWeek: meetingItem.DayOfWeek,
      topic: meetingItem.TopicNumber,
      duration: meetingItem.DurationId,
      feedback: meetingItem.FeedbackId,
      recurring: meetingItem.IsRecurring,
      startTime: localISOTime
    };
    const response = await fetch(
      `${getState().app.baseURL}JsonInteractionSvc.svc/insertMeeting`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();

    if (!response.ok) {
      // status looks bad
      const alert = {
        id: 20,
        type: "danger",
        event: "Interactions Error",
        headline: "Error accessing the insertMeeting api",
        message: `runId: ${runId}  insertMeeting : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `runId: ${runId}  insertMeeting : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 5000, true));
      dispatch(actions.actionError(alert));
      dispatch(
        createAPIError({
          type: "CREATE_API_ENTITY_ERROR",
          errorType: "warning",
          runId,
          message: "Entity error while creating insertMeeting api"
        })
      );
    } else if (json.insertUncommittedMeetingResult.status === "error") {
      const alert = {
        id: 20,
        type: "danger",
        event: "Interactions Error",
        headline: "Error in the insertMeeting response",
        message: `runId: ${runId}  insertMeeting : response status : ${json.insertUncommittedMeetingResult.statusMessage}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `runId: ${runId}  insertMeeting : response status : ${json.insertUncommittedMeetingResult.statusMessage}`
      };
      dispatch(addSingleAlert(alert, notificationData, 5000, true));
      dispatch(actions.insertError(json.insertUncommittedMeetingResult));
    } else {
      dispatch(
        actions.interactionsReceived(
          JSON.parse(json.insertUncommittedMeetingResult.payload)
        )
        // actions.meetingInserted(
        //   JSON.parse(json.insertUncommittedMeetingResult.payload)
        // )
      );
    }
  } catch (e) {
    processFailToFetch(
      e,
      getState(),
      dispatch,
      addSingleAlert,
      "insertMeeting"
    );
  }
};

export const insertRecognition = recognitionItem => async (
  dispatch,
  getState
) => {
  const runId = recognitionItem.RunId;
  try {
    const payload = {
      runId: recognitionItem.RunId,
      rewardType: recognitionItem.RewardType,
      rewardName: recognitionItem.RewardName,
      rewardId: recognitionItem.RewardId,
      dayOfWeek: recognitionItem.DayOfWeek,
      workerId: recognitionItem.WorkerId,
      workerName: recognitionItem.WorkerName
    };

    const response = await fetch(
      `${getState().app.baseURL}JsonInteractionSvc.svc/insertReward`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();

    if (!response.ok) {
      // status looks bad
      const alert = {
        id: 20,
        type: "danger",
        event: "Interactions Error",
        headline: "Error accessing the insertReward api",
        message: `runId: ${runId}  insertReward : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `runId: ${runId}  insertReward : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 5000, true));
      dispatch(actions.actionError(alert));
      dispatch(
        createAPIError({
          type: "CREATE_API_ENTITY_ERROR",
          errorType: "warning",
          runId,
          message: "Entity error while creating insertReward api"
        })
      );
    } else if (json.insertUncommittedRecognitionResult.status === "error") {
      const alert = {
        id: 20,
        type: "danger",
        event: "Interactions Error",
        headline: "Error in the insertRecognition response",
        message: `runId: ${runId}  insertRecognition : response status : ${json.insertUncommittedRecognitionResult.statusMessage}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `runId: ${runId}  insertRecognition : response status : ${json.insertUncommittedRecognitionResult.statusMessage}`
      };
      dispatch(addSingleAlert(alert, notificationData, 5000, true));
      dispatch(actions.insertError(json.insertUncommittedRecognitionResult));
    } else {
      dispatch(
        actions.interactionsReceived(
          JSON.parse(json.insertUncommittedRecognitionResult.payload)
        )
      );
    }
  } catch (e) {
    processFailToFetch(
      e,
      getState(),
      dispatch,
      addSingleAlert,
      "insertRecognition"
    );
  }
};

export const insertStakeholder = stakeholderItem => async (
  dispatch,
  getState
) => {
  const runId = stakeholderItem.RunId;
  try {
    const payload = {
      runId: stakeholderItem.RunId,
      stakeholderId: stakeholderItem.StakeholderId,
      weekday: stakeholderItem.DayOfWeek,
      interaction: stakeholderItem.Interaction,
      courseId: stakeholderItem.CourseId,
      workerId: stakeholderItem.WorkerId
    };

    const response = await fetch(
      `${
        getState().app.baseURL
      }JsonInteractionSvc.svc/insertStakeholderInteraction`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();

    if (!response.ok) {
      // status looks bad
      const alert = {
        id: 20,
        type: "danger",
        event: "Interactions Error",
        headline: "Error accessing the insertStakeholderInteraction api",
        message: `runId: ${runId}  insertStakeholderInteraction : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `runId: ${runId}  insertStakeholderInteraction : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 5000, true));
      dispatch(actions.actionError(alert));
      dispatch(
        createAPIError({
          type: "CREATE_API_ENTITY_ERROR",
          errorType: "warning",
          runId,
          message:
            "Entity error while creating insertStakeholderInteraction api"
        })
      );
    } else if (json.insertUncommittedStakeholderResult.status === "error") {
      dispatch(actions.insertError(json.insertUncommittedStakeholderResult));
    } else {
      dispatch(
        actions.interactionsReceived(
          JSON.parse(json.insertUncommittedStakeholderResult.payload)
        )
      );
    }
  } catch (e) {
    processFailToFetch(
      e,
      getState(),
      dispatch,
      addSingleAlert,
      "insertStakeholder"
    );
  }
};

export const insertAllStatusReports = (runId, weekDay) => async (
  dispatch,
  getState
) => {
  try {
    const payload = {
      runId,
      weekday: weekDay
    };

    const response = await fetch(
      `${
        getState().app.baseURL
      }JsonInteractionSvc.svc/allStakeholderStatusReport`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();

    if (!response.ok) {
      // status looks bad
      const alert = {
        id: 20,
        type: "danger",
        event: "Interactions Error",
        headline: "Error accessing the allStakeholderStatusReport api",
        message: `runId: ${runId}  allStakeholderStatusReport : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `runId: ${runId}  allStakeholderStatusReport : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 5000, true));
      dispatch(actions.actionError(alert));
      dispatch(
        createAPIError({
          type: "CREATE_API_ENTITY_ERROR",
          errorType: "warning",
          runId,
          message: "Entity error while creating allStakeholderStatusReport api"
        })
      );
    } else if (
      json.insertStatusReportForAllResult.status === "error" ||
      json.insertStatusReportForAllResult.status === "warning"
    ) {
      dispatch(actions.insertError(json.insertStatusReportForAllResult));
    } else {
      dispatch(
        actions.interactionsReceived(
          JSON.parse(json.insertStatusReportForAllResult.payload)
        )
      );
    }
  } catch (e) {
    processFailToFetch(
      e,
      getState(),
      dispatch,
      addSingleAlert,
      "insertAllStatusReports"
    );
  }
};

export const insertTraining = trainingItem => async (dispatch, getState) => {
  if (trainingItem.WorkerId === 0) {
    const alert = {
      id: 20,
      type: "danger",
      event: " Training Interaction Issue",
      headline: " Training Interaction Issue - no worker chosen",
      message: "Please select a worker to go to the training"
    };

    dispatch(addSingleAlert(alert, {}, 3000, false));
    return false;
  }
  if (trainingItem.ClassId === 0) {
    const alert = {
      id: 20,
      type: "danger",
      event: " Training Interaction Issue",
      headline: " Training Interaction Issue - no training class chosen",
      message: "Please select a training class"
    };

    dispatch(addSingleAlert(alert, {}, 3000, false));
    return false;
  }

  const runId = trainingItem.RunId;
  try {
    const payload = {
      runId: trainingItem.RunId,
      dayOfWeek: trainingItem.DayOfWeek,
      workerId: trainingItem.WorkerId,
      classId: trainingItem.ClassId
    };

    const response = await fetch(
      `${
        getState().app.baseURL
      }JsonInteractionSvc.svc/insertTrainingInteraction`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();

    if (!response.ok) {
      // status looks bad
      const alert = {
        id: 20,
        type: "danger",
        event: "Interactions Error",
        headline: "Error accessing the insertTrainingInteraction api",
        message: `runId: ${runId}  insertTrainingInteraction : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `runId: ${runId}  insertTrainingInteraction : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 5000, true));
      dispatch(actions.actionError(alert));
      dispatch(
        createAPIError({
          type: "CREATE_API_ENTITY_ERROR",
          errorType: "warning",
          runId,
          message: "Entity error while creating insertTrainingInteraction api"
        })
      );
      return false;
    } else if (json.insertUncommittedTrainingResult.status === "error") {
      dispatch(actions.insertError(json.insertUncommittedTrainingResult));
      return false;
    }
    dispatch(
      actions.trainingInserted(
        JSON.parse(json.insertUncommittedTrainingResult.payload)
      )
    );
    return true;
  } catch (e) {
    processFailToFetch(
      e,
      getState(),
      dispatch,
      addSingleAlert,
      "insertTraining"
    );
    return false;
  }
};

export const updateConference = conferenceItem => async (
  dispatch,
  getState
) => {
  const runId = conferenceItem.RunId;
  try {
    const payload = {
      id: conferenceItem.Id,
      runId: conferenceItem.RunId,
      dayOfWeek: conferenceItem.DayOfWeek,
      topic: conferenceItem.TopicNumber,
      duration: conferenceItem.DurationId,
      feedback: conferenceItem.FeedbackId,
      workerId: conferenceItem.WorkerId,
      startTime: conferenceItem.StartTime
    };
    const response = await fetch(
      `${getState().app.baseURL}JsonInteractionSvc.svc/updateConference`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();

    if (!response.ok) {
      // status looks bad
      const alert = {
        id: 20,
        type: "danger",
        event: "Interactions Error",
        headline: "Error accessing the updateConference api",
        message: `runId: ${runId}  updateConference : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `runId: ${runId}  updateConference : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 5000, true));
      dispatch(actions.actionError(alert));
      dispatch(
        createAPIError({
          type: "CREATE_API_ENTITY_ERROR",
          errorType: "warning",
          runId,
          message: "Entity error while creating updateConference api"
        })
      );
      return false;
    } else if (json.updateUncommittedConferenceResult.status === "error") {
      dispatch(
        actions.actions.updateError(json.updateUncommittedConferenceResult)
      );
      return false;
    }
    dispatch(
      actions.interactionsReceived(
        JSON.parse(json.updateUncommittedConferenceResult.payload)
      )
    );
    return true;
  } catch (e) {
    processFailToFetch(
      e,
      getState(),
      dispatch,
      addSingleAlert,
      "updateConference"
    );
    return false;
  }
};

export const updateMeeting = meetingItem => async (dispatch, getState) => {
  const runId = meetingItem.RunId;
  try {
    const payload = {
      id: meetingItem.Id,
      runId: meetingItem.RunId,
      dayOfWeek: meetingItem.DayOfWeek,
      topic: meetingItem.TopicNumber,
      duration: meetingItem.DurationId,
      feedback: meetingItem.FeedbackId,
      recurring: meetingItem.IsRecurring,
      startTime: meetingItem.StartTime
    };
    const response = await fetch(
      `${getState().app.baseURL}JsonInteractionSvc.svc/updateMeeting`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();

    if (!response.ok) {
      // status looks bad
      const alert = {
        id: 20,
        type: "danger",
        event: "Interactions Error",
        headline: "Error accessing the updateMeeting api",
        message: `runId: ${runId}  updateMeeting : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `runId: ${runId}  updateMeeting : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 5000, true));
      dispatch(actions.actionError(alert));
      dispatch(
        createAPIError({
          type: "CREATE_API_ENTITY_ERROR",
          errorType: "warning",
          runId,
          message: "Entity error while creating updateMeeting api"
        })
      );
      return false;
    } else if (json.updateUncommittedMeetingResult.status === "error") {
      dispatch(actions.updateError(json.updateUncommittedMeetingResult));
      return false;
    }
    dispatch(
      actions.interactionsReceived(
        JSON.parse(json.updateUncommittedMeetingResult.payload)
      )
    );
    return true;
  } catch (e) {
    processFailToFetch(
      e,
      getState(),
      dispatch,
      addSingleAlert,
      "updateMeeting"
    );
    return false;
  }
};

export const updateRecognition = recognitionItem => async (
  dispatch,
  getState
) => {
  const runId = recognitionItem.RunId;
  try {
    const payload = {
      id: recognitionItem.Id,
      runId: recognitionItem.RunId,
      rewardType: recognitionItem.RewardType,
      rewardName: recognitionItem.RewardName,
      rewardId: recognitionItem.RewardId,
      dayOfWeek: recognitionItem.DayOfWeek,
      workerId: recognitionItem.WorkerId,
      workerName: recognitionItem.WorkerName
    };

    const response = await fetch(
      `${getState().app.baseURL}JsonInteractionSvc.svc/updateReward`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();

    if (!response.ok) {
      // status looks bad
      const alert = {
        id: 20,
        type: "danger",
        event: "Interactions Error",
        headline: "Error accessing the updateReward api",
        message: `runId: ${runId}  updateReward : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `runId: ${runId}  updateReward : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 5000, true));
      dispatch(actions.actionError(alert));
      dispatch(
        createAPIError({
          type: "CREATE_API_ENTITY_ERROR",
          errorType: "warning",
          runId,
          message: "Entity error while creating updateReward api"
        })
      );
      return false;
    } else if (json.updateUncommittedRecognitionResult.status === "error") {
      dispatch(actions.updateError(json.updateUncommittedRecognitionResult));
      return false;
    }
    dispatch(
      actions.interactionsReceived(
        JSON.parse(json.updateUncommittedRecognitionResult.payload)
      )
    );
    return true;
  } catch (e) {
    processFailToFetch(
      e,
      getState(),
      dispatch,
      addSingleAlert,
      "updateRecognition"
    );
    return false;
  }
};

export const updateStakeholder = stakeholderItem => async (
  dispatch,
  getState
) => {
  const runId = stakeholderItem.RunId;
  try {
    const payload = {
      id: stakeholderItem.Id,
      runId: stakeholderItem.RunId,
      stakeholderId: stakeholderItem.StakeholderId,
      weekday: stakeholderItem.DayOfWeek,
      interaction: stakeholderItem.InteractionDesc,
      courseId: stakeholderItem.CourseId,
      dayOfWeek: stakeholderItem.DayOfWeek,
      workerId: stakeholderItem.WorkerId
    };

    const response = await fetch(
      `${
        getState().app.baseURL
      }JsonInteractionSvc.svc/updateStakeholderInteraction`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();

    if (!response.ok) {
      // status looks bad
      const alert = {
        id: 20,
        type: "danger",
        event: "Interactions Error",
        headline: "Error accessing the updateStakeholderInteraction api",
        message: `runId: ${runId}  updateStakeholderInteraction : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `runId: ${runId}  updateStakeholderInteraction : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 5000, true));
      dispatch(actions.actionError(alert));
      dispatch(
        createAPIError({
          type: "CREATE_API_ENTITY_ERROR",
          errorType: "warning",
          runId,
          message:
            "Entity error while creating updateStakeholderInteraction api"
        })
      );
      return false;
    } else if (json.updateUncommittedStakeholderResult.status === "error") {
      dispatch(actions.updateError(json.updateUncommittedStakeholderResult));
      return false;
    }
    dispatch(
      actions.interactionsReceived(
        JSON.parse(json.updateUncommittedStakeholderResult.payload)
      )
    );
    return true;
  } catch (e) {
    processFailToFetch(
      e,
      getState(),
      dispatch,
      addSingleAlert,
      "updateStakeholder"
    );
    return false;
  }
};

/* delete */
export const deleteConference = (id, runId) => async (dispatch, getState) => {
  try {
    const payload = {
      id,
      runId
    };

    const response = await fetch(
      `${getState().app.baseURL}JsonInteractionSvc.svc/deleteConference`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();

    if (!response.ok) {
      // status looks bad
      const alert = {
        id: 20,
        type: "danger",
        event: "Interactions Error",
        headline: "Error accessing the deleteConference api",
        message: `runId: ${runId}  deleteConference : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `runId: ${runId}  deleteConference : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 5000, true));
      dispatch(actions.actionError(alert));
      dispatch(
        createAPIError({
          type: "CREATE_API_ENTITY_ERROR",
          errorType: "warning",
          runId,
          message: "Entity error while creating deleteConference api"
        })
      );
      return false;
    } else if (json.deleteUncommittedConferenceResult.status === "error") {
      dispatch(actions.deleteError(json.deleteUncommittedConferenceResult));
      return false;
    }
    dispatch(
      actions.interactionsReceived(
        JSON.parse(json.deleteUncommittedConferenceResult.payload)
      )
    );
    return true;
  } catch (e) {
    processFailToFetch(
      e,
      getState(),
      dispatch,
      addSingleAlert,
      "deleteConference"
    );
    return false;
  }
};

export const deleteMeeting = (id, runId) => async (dispatch, getState) => {
  try {
    const payload = {
      id,
      runId
    };

    const response = await fetch(
      `${getState().app.baseURL}JsonInteractionSvc.svc/deleteMeeting`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();

    if (!response.ok) {
      // status looks bad
      const alert = {
        id: 20,
        type: "danger",
        event: "Interactions Error",
        headline: "Error accessing the deleteMeeting api",
        message: `runId: ${runId}  deleteMeeting : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `runId: ${runId}  deleteMeeting : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 5000, true));
      dispatch(actions.actionError(alert));
      dispatch(
        createAPIError({
          type: "CREATE_API_ENTITY_ERROR",
          errorType: "warning",
          runId,
          message: "Entity error while creating deleteMeeting api"
        })
      );
      return false;
    } else if (json.deleteUncommittedMeetingResult.status === "error") {
      dispatch(actions.deleteError(json.deleteUncommittedMeetingResult));
      return false;
    }
    dispatch(
      actions.interactionsReceived(
        JSON.parse(json.deleteUncommittedMeetingResult.payload)
      )
    );
    return true;
  } catch (e) {
    processFailToFetch(
      e,
      getState(),
      dispatch,
      addSingleAlert,
      "deleteMeeting"
    );
    return false;
  }
};

export const deleteRecognition = (id, runId) => async (dispatch, getState) => {
  try {
    const payload = {
      id,
      runId
    };

    const response = await fetch(
      `${getState().app.baseURL}JsonInteractionSvc.svc/deleteReward`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();

    if (!response.ok) {
      // status looks bad
      const alert = {
        id: 20,
        type: "danger",
        event: "Interactions Error",
        headline: "Error accessing the deleteReward api",
        message: `runId: ${runId}  deleteReward : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `runId: ${runId}  deleteReward : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 5000, true));
      dispatch(actions.actionError(alert));
      dispatch(
        createAPIError({
          type: "CREATE_API_ENTITY_ERROR",
          errorType: "warning",
          runId,
          message: "Entity error while creating deleteReward api"
        })
      );
      return false;
    } else if (json.deleteUncommittedRecognitionResult.status === "error") {
      dispatch(actions.deleteError(json.deleteUncommittedRecognitionResult));
      return false;
    }
    dispatch(
      actions.interactionsReceived(
        JSON.parse(json.deleteUncommittedRecognitionResult.payload)
      )
    );
    return true;
  } catch (e) {
    processFailToFetch(
      e,
      getState(),
      dispatch,
      addSingleAlert,
      "deleteRecognition"
    );
    return false;
  }
};

export const deleteStakeholder = (id, runId) => async (dispatch, getState) => {
  try {
    const payload = {
      id,
      runId
    };

    const response = await fetch(
      `${
        getState().app.baseURL
      }JsonInteractionSvc.svc/deleteStakeholderInteraction`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();
    if (!response.ok) {
      // status looks bad
      const alert = {
        id: 20,
        type: "danger",
        event: "Interactions Error",
        headline: "Error accessing the deleteStakeholderInteraction api",
        message: `runId: ${runId}  deleteStakeholderInteraction : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `runId: ${runId}  deleteStakeholderInteraction : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 5000, true));
      dispatch(actions.actionError(alert));
      dispatch(
        createAPIError({
          type: "CREATE_API_ENTITY_ERROR",
          errorType: "warning",
          runId,
          message:
            "Entity error while creating deleteStakeholderInteraction api"
        })
      );
      return false;
    } else if (json.deleteUncommittedStakeholderResult.status === "error") {
      dispatch(actions.deleteError(json.deleteUncommittedStakeholderResult));
      return false;
    }
    dispatch(
      actions.interactionsReceived(
        JSON.parse(json.deleteUncommittedStakeholderResult.payload)
      )
    );
    return true;
  } catch (e) {
    processFailToFetch(
      e,
      getState(),
      dispatch,
      addSingleAlert,
      "deleteStakeholder"
    );
    return false;
  }
};

export const deleteTraining = (ids, runId) => async (dispatch, getState) => {
  try {
    const payload = {
      ids,
      runId
    };
    const response = await fetch(
      `${
        getState().app.baseURL
      }JsonInteractionSvc.svc/deleteTrainingInteraction`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();
    if (!response.ok) {
      // status looks bad
      const alert = {
        id: 20,
        type: "danger",
        event: "Interactions Error",
        headline: "Error accessing the deleteTrainingInteraction api",
        message: `runId: ${runId}  deleteTrainingInteraction : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `runId: ${runId}  deleteTrainingInteraction : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 5000, true));
      dispatch(actions.actionError(alert));
      dispatch(
        createAPIError({
          type: "CREATE_API_ENTITY_ERROR",
          errorType: "warning",
          runId,
          message: "Entity error while creating deleteTrainingInteraction api"
        })
      );
      return false;
    } else if (json.deleteUncommittedTrainingResult.status === "error") {
      dispatch(actions.deleteError(json.deleteUncommittedTrainingResult));
      return false;
    }
    dispatch(actions.trainingDeleted(ids));
    return true;
  } catch (e) {
    processFailToFetch(
      e,
      getState(),
      dispatch,
      addSingleAlert,
      "deleteTraining"
    );
    return false;
  }
};

export const updateTraining2 = trainingItem => async (dispatch, getState) => {
  try {
    const trs = getState().execution.interactions.trainingInteractions.filter(
      tr => tr.Id === trainingItem.Id
    );

    const ids = [];
    if (trs !== undefined && trs.length > 0) {
      const classId = trs[0].ClassId;
      const workerId = trs[0].WorkerId;

      let keys = [];
      let ti = {};
      keys = Object.keys(
        getState().execution.interactions.trainingInteractions
      );
      for (let i = 0; i < keys.length; i++) {
        ti = getState().execution.interactions.trainingInteractions[i];

        if (ti.ClassId === classId && ti.WorkerId === workerId) {
          ids.push(ti.Id);
        }
      }
      await dispatch(deleteTraining(ids, trainingItem.runId));

      await dispatch(insertTraining(trainingItem));
      return true;
    }
    return false;
  } catch (e) {
    processFailToFetch(
      e,
      getState(),
      dispatch,
      addSingleAlert,
      "updateTraining2"
    );
    return false;
  }
};

// export const updateTraining = trainingItem => {
//   return function(dispatch, getState) {
//
//     var trs = getState().execution.interactions.trainingInteractions.filter(
//       tr => tr.Id === trainingItem.Id
//     );
//
//     var ids = [];
//     if (trs !== undefined && trs.length > 0) {
//       var classId = trs[0].ClassId;
//       var workerId = trs[0].WorkerId;
//       for (let ti of getState().execution.interactions.trainingInteractions) {
//         if (ti.ClassId === classId && ti.WorkerId === workerId) {
//           ids.push(ti.Id);
//         }
//       }
//     }
//
//     return;
//   };
// };
