import { createAction, handleActions } from "redux-actions";
//

import {processAssignmentsSuccess} from "redux/Project/actions"
import {receiveResourcePlan} from "redux/Resource/actions";
// ------------------------------------
// Constants
// ------------------------------------

export const SHIFT_ASSIGNMENTS_SUCCESS = "SHIFT_ASSIGNMENTS_SUCCESS";
export const ASSIGNMENT_ERROR = "ASSIGNMENT_ERROR";

// ------------------------------------
// Actions
// ------------------------------------

export const actionError = createAction(ASSIGNMENT_ERROR, payload => payload);

export const processShiftAssignmentsSuccess = createAction(
  SHIFT_ASSIGNMENTS_SUCCESS,
  payload => payload
);

export const processTaskAssignmentShift = (staffingObj, actionList) => async (
  dispatch,
  getState
) => {
  try {
    const payload = {
      runId: getState().project.runId,
      staffingObj,
      actionList
    };
    const response = await fetch(
      `${getState().app.baseURL}JsonProjectSvc.svc/updateStaffingDecisions`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();

    if (!response.ok) {
      throw new Error(
        `updateStaffingDecisionsResult : http request response error: ${status}`
      );
    } else if (json.updateStaffingDecisionsResult.status === "error") {
      throw new Error(
        `updateStaffingDecisionsResult : http request response error: ${json.updateStaffingDecisionsResult.statusMessage}`
      );
    }
    const jsonPayload = JSON.parse(json.updateStaffingDecisionsResult.payload)
    await Promise.all([
      dispatch(processAssignmentsSuccess(jsonPayload.assignments)), dispatch(receiveResourcePlan(jsonPayload.resourcePlan))]);


  return true;
  } catch (e) {
  throw e
  }
};

const initialState = {};
// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(
  {
    [SHIFT_ASSIGNMENTS_SUCCESS]: (state, { payload }) => ({
      ...state
    }),

    [ASSIGNMENT_ERROR]: (state, { payload }) => ({
      ...state
    }),
    default: state => state
  },
  initialState
);
