import { requestUpdatedBudget } from "redux/Project/thunks";
import { fetchChartData} from "redux/Report/thunks";
import { closeModal } from "redux/Modal/ModalModule";
import {
  addSingleAlert,
  createAPIError
} from "redux/Notification/NotificationModule";
import { processFailToFetch } from "utilities/handleFetchErrors";

import {
  receiveTrainings,
  deleteTrainingPlanSuccess,
  deleteError,
  saveError,
  actionError,
  saveTrainingPlanSuccess
} from "./actions";

export function fetchTraining(runId) {
  return async (dispatch, getState) => {
    try {
      const payload = { runId };
      const response = await fetch(
        `${getState().app.baseURL}JsonPlanningSvc.svc/trainingPlan`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8"
          },
          method: "post",
          body: JSON.stringify(payload)
        }
      );
      // api call http status
      const { status } = response;
      // wait for json respone to come back
      const json = await response.json();

      if (!response.ok) {
        // status looks bad
        const alert = {
          id: 20,
          type: "danger",
          event: "Stakeholder Error",
          headline: "Error accessing the trainingPlan api",
          message: `runId: ${runId}  fetch trainingPlan : response status : ${status.toString()}`
        };
        const notificationData = {
          worker: "thunk",
          week: 0,
          info: `runId: ${runId}  trainingPlan : response status : ${status.toString()}`
        };
        dispatch(addSingleAlert(alert, notificationData, 0, true));
        dispatch(actionError(alert));
        dispatch(
          createAPIError({
            type: "CREATE_API_ENTITY_ERROR",
            errorType: "warning",
            runId,
            message: "Entity error while creating trainingPlan api"
          })
        );
        return false;
      } else if (json.getTrainingsResult.status === "error") {
        const alert = {
          id: 2,
          type: "danger",
          event: "training",
          headline: "Unable to fetch training ",
          message: json.getTrainingsResult.statusMessage
        };
        const notificationData = {
          worker: getState().auth.user.UserName,
          week: 0,
          info: "trainingPlan API"
        };
        dispatch(addSingleAlert(alert, notificationData, 0, true));
        dispatch(actionError(json.getTrainingsResult.statusMessage));
        return false;
      }
      dispatch(receiveTrainings(JSON.parse(json.getTrainingsResult.payload)));
      return true;
    } catch (e) {
      processFailToFetch(e,getState(), dispatch, addSingleAlert, "fetchTraining");
      return false;
    }
  };
}

export const deleteTraining = (index, id, runId, data) => async (
  dispatch,
  getState
) => {
  try {
    const payload = {
      id
    };

    const response = await fetch(
      `${getState().app.baseURL}JsonPlanningSvc.svc/trainingPlan/delete`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();

    if (!response.ok) {
      // status looks bad
      const alert = {
        id: 20,
        type: "danger",
        event: "training Error",
        headline: "Error accessing the trainingPlan api",
        message: `runId: ${runId}  delete trainingPlan : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `runId: ${runId}  delete : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 0, true));
      dispatch(actionError(alert));
      dispatch(
        createAPIError({
          type: "CREATE_API_ENTITY_ERROR",
          errorType: "warning",
          runId,
          message: "Entity error while creating delete training api"
        })
      );
      return false;
    } else if (json.deleteTrainingPlanItemResult.status === "error") {
      const alert = {
        id: 2,
        type: "danger",
        event: "training",
        headline: "Unable to delete Training Course",
        message: json.deleteTrainingPlanItemResult.statusMessage
      };
      const notificationData = {
        worker: data.WorkerName,
        week: data.WeekNumber,
        info: data.ClassName
      };
      dispatch(addSingleAlert(alert, notificationData, 0, true));

      dispatch(deleteError(json.deleteTrainingPlanItemResult.statusMessage));
      return false;
    }
    const alert = {
      id: 20,
      type: "warning",
      event: "training",
      headline: "Training Course Deleted",
      message: `${data.ClassName} removed `
    };
    const notificationData = {
      worker: data.WorkerName,
      week: data.WeekNumber,
      info: data.ClassName
    };
    await dispatch(addSingleAlert(alert, notificationData, 300, false));
    dispatch(deleteTrainingPlanSuccess(index, json));
    dispatch(fetchTraining(runId));
    dispatch(requestUpdatedBudget(runId));
    dispatch(fetchChartData(runId));

    return true;
  } catch (e) {
    processFailToFetch(e,getState(), dispatch, addSingleAlert, "deleteTraining");
    return false;
  }
};

export const saveTraining = data => async (dispatch, getState) => {
  try {

    const newItem = data.ID === 0; // sets new item to true if the id is 0
    const { RunId } = data;

    const payload = { trainingDTO: data };

    if (data.WorkerId === 0 && data.TeamTraining === false) {
      const alert = {
        id: 20,
        type: "danger",
        event: "Add Training Issue",
        headline: "Add Training Issue - no resource chosen",
        message: "Please select a resource to go to the training"
      };

      dispatch(addSingleAlert(alert, {}, 3000, false));
      return false;
    }
    if (data.ClassId === 0) {
      const alert = {
        id: 20,
        type: "danger",
        event: "Add Training Issue",
        headline: "Add Training Issue - no training class chosen",
        message: "Please select a training class"
      };

      dispatch(addSingleAlert(alert, {}, 3000, false));
      return false;
    }
    const response = await fetch(
      `${getState().app.baseURL}JsonPlanningSvc.svc/trainingPlan/save`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();

    if (!response.ok) {
      // status looks bad
      const alert = {
        id: 20,
        type: "danger",
        event: "Risk Error",
        headline: "Error accessing the training plan  save api",
        message: `runId: ${
          data.RunId
        }  save training plan api : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `runId: ${
          data.RunId
        }  save training plan : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 0, true));
      dispatch(actionError(alert));
      dispatch(
        createAPIError({
          type: "CREATE_API_ENTITY_ERROR",
          errorType: "warning",
          runId: data.RunId,
          message: "Entity error while creating save training plan api"
        })
      );
      return false;
    } else if (json.saveTrainingPlanItemResult.status === "error") {
      const alert = {
        id: 2,
        type: "danger",
        event: "training",
        headline: "Unable to save training ",
        message: json.saveTrainingPlanItemResult.statusMessage
      };
      const notificationData = {
        worker: getState().auth.user.UserName,
        week: 0,
        info: "save Training API"
      };
      dispatch(addSingleAlert(alert, notificationData, 0, true));
      dispatch(saveError(json.saveTrainingPlanItemResult.statusMessage));
      return false;
    }
    const pl = {};
    pl.newItem = newItem;
    pl.result = json.saveTrainingPlanItemResult.payload;
    dispatch(saveTrainingPlanSuccess(pl));
    dispatch(fetchTraining(RunId));
    const alert = {
      id: 2,
      type: "success",
      event: "training",
      headline:
        newItem === true ? "Training Course Added" : "Training Course Updated",
      message: `${data.ClassName} to Week ${data.WeekNumber} for ${
        data.WorkerName
      }`
    };
    const notificationData = {
      worker: data.WorkerName,
      week: data.WeekNumber,
      info: data.ClassName
    };
    dispatch(addSingleAlert(alert, notificationData, 3000, true));
    dispatch(requestUpdatedBudget(data.RunId));
    dispatch(fetchChartData(data.RunId));


    dispatch(closeModal());
    return true;
  } catch (e) {
    processFailToFetch(e,getState(), dispatch, addSingleAlert, "saveTraining");
    return false;
  }
};

// export function fetchAllWorkers(runId) {
//   return function(dispatch) {
//     return fetch(baseURL + "JsonPlanningSvc.svc/workers/" + runId)
//       .then(response => response.json())
//       .then(json => dispatch(receiveAllWorkers(json)));
//     // In a real world app, you also want to
//     // catch any error in the network call.
//   };
// }

// export function fetchAllTrainings(runId) {
//   return function(dispatch) {
//     return fetch(baseURL + "JsonPlanningSvc.svc/trainings/" + runId)
//       .then(response => response.json())
//       .then(json => dispatch(receiveAllTrainings(json)));
//     // In a real world app, you also want to
//     // catch any error in the network call.
//   };
// }
