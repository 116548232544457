// TODO - implement the delete resource api return a collection.  don't need to refetchimport

import { requestUpdatedBudget } from "redux/Project/thunks";
import { fetchChartData } from "redux/Report/thunks";
import { toggleLoading } from "app/redux/actions";
import {
  budgetReceived,
  processAssignmentsSuccess
} from "redux/Project/actions";
import {
  addSingleAlert,
  createAPIError
} from "redux/Notification/NotificationModule";
import { processFailToFetch } from "utilities/handleFetchErrors";

import * as actions from "./actions";

export function setFilterSkillId(id) {
  return async (dispatch) => {
    dispatch(actions.setFilterSkillId(id));
  };
}

export const fetchResourcePlan = (runId) => async (dispatch, getState) => {
  try {
    dispatch(toggleLoading());

    const payload = { runId };
    if (runId === 0) {
      return false;
    }
    const response = await fetch(
      `${getState().app.baseURL}JsonPlanningSvc.svc/resourcePlan`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();

    if (!response.ok) {
      // status looks bad
      const alert = {
        id: 20,
        type: "danger",
        event: "Resource Error",
        headline: "Error accessing the resourcePlan api",
        message: `runId: ${runId}  fetch resourcePlan : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `runId: ${runId}  resourcePlan : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 0, true));
      dispatch(actions.actionError(alert));
      dispatch(
        createAPIError({
          type: "CREATE_API_ENTITY_ERROR",
          errorType: "warning",
          runId,
          message: "Entity error while creating resourcePlan api"
        })
      );
      return false;
    } else if (json.getCurrentResourcePlanResult.status === "error") {
      const alert = {
        id: 20,
        type: "danger",
        event: "Resource Error",
        headline: "Error in the fetchResourcePlan response",
        message: `runId: ${runId}  fetchResourcePlan : ${json.getCurrentResourcePlanResult.statusMessage}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `runId: ${runId}  fetchResourcePlan : ${json.getCurrentResourcePlanResult.statusMessage}`
      };
      dispatch(addSingleAlert(alert, notificationData, 0, true));
      dispatch(
        actions.actionError(json.getCurrentResourcePlanResult.statusMessage)
      );
      return false;
    }
    dispatch(
      actions.receiveResourcePlan(
        JSON.parse(json.getCurrentResourcePlanResult.payload)
      )
    );
    dispatch(toggleLoading());

    return true;
  } catch (e) {
    processFailToFetch(
      e,
      getState(),
      dispatch,
      addSingleAlert,
      "fetchResourcePlan"
    );
    dispatch(toggleLoading());

    return false;
  }
};

export const insertResourcePlan = (runId, data, startingWeek) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(toggleLoading());

    const payload = {
      runId,
      resourceId: data.WorkerId,
      resourceName: data.WorkerName,
      skillId: data.SkillId,
      skillName: data.Title,
      startingWeek
    };

    const response = await fetch(
      `${getState().app.baseURL}JsonPlanningSvc.svc/resourcePlan/insert`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();
    if (!response.ok) {
      throw new Error(
        `insertResourcePlanResult : http request response error: ${status}`
      );
    } else if (json.insertResourcePlanResult.status === "error") {
      throw new Error(
        `insertResourcePlanResult : http request response error: ${json.insertResourcePlanResult.statusMessage}`
      );
    }
    const jsonPayload = JSON.parse(json.insertResourcePlanResult.payload);

    await Promise.all([
      dispatch(processAssignmentsSuccess(jsonPayload.assignments)),
      dispatch(actions.receiveResourcePlan(jsonPayload.resourcePlan))
    ]);

    dispatch(requestUpdatedBudget(runId));
    dispatch(fetchChartData(runId));

    dispatch(toggleLoading());

    return true;
  } catch (e) {
    dispatch(toggleLoading());

    throw e;
  }
};

// data is existing resource plan header with new header data, not item data
export const saveResourcePlan = (data, staffWeek) => async (
  dispatch,
  getState
) => {
  const runId = data.RunId;
  let payload = {};

  try {
    payload = {
      id: data.Id,
      runId: data.RunId,
      resourceId: data.ResourceId,
      resourceName: data.ResourceName,
      skillId: data.SkillId,
      skillName: data.SkillName
    };
    const response = await fetch(
      `${getState().app.baseURL}JsonPlanningSvc.svc/resourcePlan/update`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();

    if (!response.ok) {
      // status looks bad
      const alert = {
        id: 20,
        type: "danger",
        event: "Resource Error",
        headline: "Error accessing the resourcePlan/update api",
        message: `runId: ${runId}  fetch resourcePlan/update : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `runId: ${runId}  resourcePlan/update : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 0, true));
      dispatch(actions.actionError(alert));
      dispatch(
        createAPIError({
          type: "CREATE_API_ENTITY_ERROR",
          errorType: "warning",
          runId,
          message: "Entity error while creating resourcePlan/update api"
        })
      );
      return false;
    } else if (json.updateResourcePlanResult.status === "error") {
      const alert = {
        id: 20,
        type: "danger",
        event: "Resource Error",
        headline: "Error in the resourcePlan/update response",
        message: `runId: ${runId}  status ${json.updateResourcePlanResult.statusMessage}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `runId: ${runId}  status ${json.updateResourcePlanResult.statusMessage}`
      };
      dispatch(addSingleAlert(alert, notificationData, 0, true));
      dispatch(
        actions.actionError(json.updateResourcePlanResult.statusMessage)
      );
      return false;
    }
    dispatch(
      actions.insertResourcePlanSuccess(
        JSON.parse(json.updateResourcePlanResult.payload)
      )
    );
    dispatch(requestUpdatedBudget(data.RunId));
    dispatch(fetchChartData(data.RunId));

    return true;
  } catch (e) {
    processFailToFetch(
      e,
      getState(),
      dispatch,
      addSingleAlert,
      "saveResourcePlan"
    );
    return false;
  }
};

// data is existing resource plan header with new header data, not item data
export const deleteResourcePlan = (index, id, runId, resourceId = 0) => async (
  dispatch,
  getState
) => {
  try {
    const payload = {
      id,
      runId
    };
    const response = await fetch(
      `${getState().app.baseURL}JsonPlanningSvc.svc/resourcePlan/delete`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();
    if (!response.ok) {
      throw new Error(
        `deleteResourcePlanResult : http request response error: ${status}`
      );
    } else if (json.deleteResourcePlanResult.status === "error") {
      throw new Error(
        `deleteResourcePlanResult : http request response error: ${json.deleteResourcePlanResult.statusMessage}`
      );
    }
    const jsonPayload = JSON.parse(json.deleteResourcePlanResult.payload);

    await Promise.all([
      dispatch(processAssignmentsSuccess(jsonPayload.assignments)),
      dispatch(actions.receiveResourcePlan(jsonPayload.resourcePlan))
    ]);
    dispatch(budgetReceived(jsonPayload.budgetList));

    return true;
  } catch (e) {
    processFailToFetch(
      e,
      getState(),
      dispatch,
      addSingleAlert,
      "deleteResourcePlan"
    );
    return false;
  }
};

export const saveResourceItem = (data) => async (dispatch, getState) => {
  const payload = {
    displayObj: data.displayObj,
    detailId: data.detailId,
    detailId2: data.detailId2 ? data.detailId2 : 0,
    newAction: data.newAction
  };
  try {
    dispatch(actions.updateResourceItemState(data.displayObj));

    const response = await fetch(
      `${getState().app.baseURL}JsonPlanningSvc.svc/resourcePlanItem/save`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();
    if (!response.ok) {
      throw new Error(
        `saveResourcePlanItemResult : http request response error: ${status}`
      );
    } else if (json.saveResourcePlanItemResult.status === "error") {
      throw new Error(
        `saveResourcePlanItemResult : http request response error: ${json.saveResourcePlanItemResult.statusMessage}`
      );
    }
    const jsonPayload = JSON.parse(json.saveResourcePlanItemResult.payload);

    await Promise.all([
      dispatch(processAssignmentsSuccess(jsonPayload.assignments)),
      dispatch(budgetReceived(jsonPayload.budgetList)),
      dispatch(actions.receiveResourcePlan(jsonPayload.resourcePlan))
    ]);

    dispatch(fetchChartData(data.displayObj.RunId));

    return true;
  } catch (e) {
    throw e;
  }
};
