import {
  addSingleAlert,
  createAPIError
} from "redux/Notification/NotificationModule";
import { processFailToFetch } from "utilities/handleFetchErrors";

import * as actions from "./actions";

export const fetchGanttPng = (runId) => {
  return async (dispatch, getState) => {
    try {
      const payload = {
        runId
      };
      var outside;
      const response = await fetch(
        `${getState().app.baseURL}JsonReportingSvc.svc/ganttPng/${runId.toString()}`,
        {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "image/png"
          }
        }
      );
      let buffer = await response.arrayBuffer();
      let imageFile = new Blob([buffer])
      const imgUrl = URL.createObjectURL(imageFile);
      return imgUrl
    } catch (e) {
      console.log(e);
    }
  };
};

function arrayBufferToBase64(buffer) {
  var binary = '';
  var bytes = [].slice.call(new Uint8Array(buffer));

  bytes.forEach((b) => binary += String.fromCharCode(b));

  return window.btoa(binary);
};

export function fetchHistoryData(runId) {
  return async (dispatch, getState) => {
    let reportLevel = 100;

    if (getState().auth.user.UserName.toLowerCase().includes("paccissuser") ||
    getState().auth.user.UserName.toLowerCase().includes("paccesstest")) {
      reportLevel = 500;
    }
    try {
      const payload = {
        runId,
        reportLevel
      };
      const response = await fetch(
        `${getState().app.baseURL}JsonReportingSvc.svc/allHistory`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8"
          },
          method: "post",
          body: JSON.stringify(payload)
        }
      );
      // api call http status
      const { status } = response;
      // wait for json respone to come back
      const json = await response.json();

      if (!response.ok) {
        // status looks bad
        const alert = {
          id: 20,
          type: "danger",
          event: "Report allHistory Error",
          headline: "Error accessing the allHistory api",
          message: `runId: ${runId}  allHistory : response status : ${status.toString()}`
        };
        const notificationData = {
          worker: "thunk",
          week: 0,
          info: `runId: ${runId}  allHistory : response status : ${status.toString()}`
        };
        dispatch(addSingleAlert(alert, notificationData, 0, true));
        dispatch(actions.reportError(alert));
        dispatch(
          createAPIError({
            type: "CREATE_API_ENTITY_ERROR",
            errorType: "warning",
            runId,
            message: "Entity error while creating allHistory api"
          })
        );
        return false;
      } else if (json.getAllHistoryForProjectResult.status === "error") {
        const alert = {
          id: 20,
          type: "danger",
          event: "Report allHistory Error",
          headline: "Error in the allHistory api response",
          message: `runId: ${runId}  allHistory :  status message : ${json.getAllHistoryForProjectResult.statusMessage}`
        };
        const notificationData = {
          worker: "thunk",
          week: 0,
          info: `runId: ${runId}  allHistory :  status message : ${json.getAllHistoryForProjectResult.statusMessage}`
        };
        dispatch(addSingleAlert(alert, notificationData, 0, true));
        dispatch(
          actions.reportError(json.getAllHistoryForProjectResult.statusMessage)
        );
        return false;
      }
      dispatch(
        actions.historyReceived(
          JSON.parse(json.getAllHistoryForProjectResult.payload)
        )
      );
      return true;
    } catch (e) {
      processFailToFetch(
        e,
        getState(),
        dispatch,
        addSingleAlert,
        "fetchHistoryData"
      );
      return false;
    }
  };
}

export const fetchChartData = (runId) => async (dispatch, getState) => {
  try {
    const payload = { runId };
    if (
      runId === undefined ||
      runId === null ||
      runId === 0 ||
      getState().auth.isAuthenticated === false
    ) {
      return false;
    }
    const response = await fetch(
      `${getState().app.baseURL}JsonReportingSvc.svc/reports`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();

    if (!response.ok) {
      // status looks bad
      const alert = {
        id: 20,
        type: "danger",
        event: "Report fetchReportingData Error",
        headline: "Error accessing the reports api",
        message: `runId: ${runId}  reports : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `runId: ${runId}  reports : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 0, true));
      dispatch(actions.reportError(alert));
      dispatch(
        createAPIError({
          type: "CREATE_API_ENTITY_ERROR",
          errorType: "warning",
          runId,
          message: "Entity error while creating api"
        })
      );
      return false;
    } else if (json.getReportDataResult.status === "error") {
      const alert = {
        id: 20,
        type: "danger",
        event: "Report fetchReportingData Error",
        headline: "Error in the reports api response",
        message: `runId: ${runId}  reports :  status message : ${json.getReportDataResult.statusMessage}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `runId: ${runId}  reports :  status message : ${json.getReportDataResult.statusMessage}`
      };
      dispatch(addSingleAlert(alert, notificationData, 0, true));
      dispatch(actions.reportError(json.getReportDataResult.statusMessage));
      return false;
    }
    dispatch(
      actions.chartDataReceived(JSON.parse(json.getReportDataResult.payload))
    );
    // dispatch(fetchHistoryData(runId));
    return true;
  } catch (e) {
    processFailToFetch(
      e,
      getState(),
      dispatch,
      addSingleAlert,
      "fetchReportingData"
    );
    return false;
  }
};

export const fetchLaborSummaryData = (runId) => async (dispatch, getState) => {
  const payload = {
    runId
  };
  try {
    const response = await fetch(
      `${getState().app.baseURL}JsonReportingSvc.svc/laborSummary`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();
    if (!response.ok) {
      throw new Error(
        `getLaborSummaryDataResult : http request response error: ${status}`
      );
    } else if (json.getLaborSummaryDataResult.status === "error") {
      throw new Error(
        `getLaborSummaryDataResult : http request response error: ${json.getLaborSummaryDataResult.statusMessage}`
      );
    }

    await dispatch(
      actions.laborSummaryReceived(
        JSON.parse(json.getLaborSummaryDataResult.payload)
      )
    );
    return true;
  } catch (e) {
    throw e;
  }
};
